import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import M from 'materialize-css';
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, USUARIO_EDOCTA } from "../properties";
import { decryptAES, encryptAES, encryptAESEdocta, PREFIJO_CONSTANCIA } from '../utils'
import { toogleLoad } from "../redux/slices/appslice";
import { ICONS } from "../assets/icons";
import { logEvent } from "firebase/analytics";
import { useAnalytics } from "../AnalyticsContext";

function ConstanciaFiscal() {
    const TEXTO_EXITO = "¡Listo! Tu constancia deducible se ha descargado en tu dispositivo.";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appData = useSelector((state) => state.appData)
    const analytics = useAnalytics();
    const [anios, setAnios] = useState([]);
    const [pasos, setPasos] = useState(1);
    const [anioSelected, setAnioSelected] = useState('');
    const [tipoSelected, setTipoSelected] = useState('');
    const [documento, setDocumento] = useState(false);
    const [saldosFecha, setSaldosFecha] = useState(false);
    const [claveEmpleado, setClaveEmpleado] = useState('');
    const [muestraError, setMuestraError] = useState('');
    const [muestraExito, setMuestraExito] = useState(false);

    const [numClient, setNumCliente] = useState('');

    useEffect(() => {
        obtenerUltimos10Anios();

    }, [])

    useEffect(() => {

        if (appData.saldosFecha) {

            for (let saldo of appData.saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {

                        setSaldosFecha(saldo);
                    }
                }
            }
        }

        if (appData.saldosTrimestrales && appData.saldosTrimestrales.saldosAgrupadosXCuenta) {

            for (let st of appData.saldosTrimestrales.saldosAgrupadosXCuenta) {
                if (st.Producto && st.Producto.TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {
                    console.log(st.Producto);
                    setNumCliente(st.Producto.NumCliente);
                }
            }
        }



        if (appData.user) {
            setClaveEmpleado(appData.user.Clave);
        }
    }, [appData])

    useEffect(() => {
        if (anios.length) {
            M.FormSelect.init(document.querySelectorAll('select'), {});
        }
    }, [anios]);


    function obtenerUltimos10Anios() {
        const anioActual = new Date().getFullYear(); // Obtener el año actual
        const anios = [];

        for (let i = 0; i < 10; i++) {
            anios.push(anioActual - i); // Agregar el año correspondiente al arreglo
        }

        setAnios(anios);
    }

    const getDocumento = async () => {
        setMuestraExito(false);
        setMuestraError(false);

        dispatch(toogleLoad(true));

        const nombreDoc = `${PREFIJO_CONSTANCIA}${numClient}${claveEmpleado}${anioSelected}`;

        let dataToEncrypt = {
            "UsuarioServicio": USUARIO_EDOCTA,
            "NombreDoc": encryptAESEdocta(nombreDoc),
            "TipoDoc": tipoSelected,
            "Anio": encryptAES(parseInt(anioSelected)),
            "Tipo": 'C'
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToEncrypt)
        };

        fetch(`${BASE_URL}/clientes/edoCta`, requestOptions)
            .then(response => response.json())
            .then((data) => {

                dispatch(toogleLoad(false));

                if (data.response && data.response.Exito === 200 && data.response.Documento) {

                    logEvent(analytics, "constancia_obtenida", {
                        screen_name: "constancia_duducible",
                        plataforma: "web"
                    });

                    if (tipoSelected === 'XML') {
                        const downloadLink = document.createElement("a");
                        const linkSource = `data:application/xml;base64,${decryptAES(data.response.Documento)}`;
                        downloadLink.setAttribute("target", "_blank");
                        downloadLink.href = linkSource;
                        downloadLink.download = `${nombreDoc}.${tipoSelected.toLowerCase()}`;;
                        downloadLink.click();

                        setMuestraExito(TEXTO_EXITO);

                        setTimeout(() => {
                            setMuestraExito(false);
                        }, 5000)
                    } else {

                        setDocumento(decryptAES(data.response.Documento));
                        setPasos(2);
                    }
                } else if (data.response && data.response.Documento === null) {
                    setMuestraError('El año seleccionado no cuenta con una constancia de deducibles.');
                }
                else {
                    setMuestraError(data.MensajeError);
                }


            }).catch((e) => {

                dispatch(toogleLoad(false));
                setMuestraError("Se produjo un error inesperado, intente nuevamente.");
            });

    }


    return (

        <div className="container" style={{ minHeight: '100vh' }}>
            <div className="row mt-2">

                {
                    muestraError ?

                        <div className="col s12">
                            <div style={{
                                background: '#F27468',
                                color: 'white',
                                borderRadius: '8px',
                                padding: '8px 16px 8px 16px',
                                fontWeight: 'bold'

                            }}>
                                <img src={ICONS.cancelar} alt="error" className="left iconCancelar"></img> {muestraError}
                            </div>
                        </div>


                        : null
                }


                {
                    muestraExito ?

                        <div className="col s12">
                            <div style={{
                                background: '#86CB7B',
                                color: 'white',
                                borderRadius: '8px',
                                padding: '8px 16px 8px 16px',
                                fontWeight: 'bold'

                            }}>
                                <img src={ICONS.checkOutline} alt="error" className="left iconCancelar"></img> {muestraExito}
                            </div>
                        </div>


                        : null
                }


                <div className="col s8 left-align">
                    <p className='cursorPointer'
                        onClick={() => {

                            if (tipoSelected === 'PDF' && pasos === 2) {
                                setPasos(1);
                            } else {
                                navigate('/plandepensiones');
                            }

                        }}
                    ><i className='material-icons left'>keyboard_backspace</i>Plan de Pensiones</p>
                    <h5 className="txtBold">Constancia Deducible</h5>
                </div>
                <div className="col s4 right-align" style={{ paddingTop: '10px' }}>
                    {
                        pasos === 2 ?

                            <a className="waves-effect waves-light btn ft-14 txt_main-dark-blue white br-12 btnCancel txtBold"
                                onClick={() => {
                                    logEvent(analytics, "constanciadeducible_cancelacion", {
                                        screen_name: "rebalanceo",
                                        plataforma: "web"
                                    });
                                    navigate('/plandepensiones');
                                }}
                            ><i className="material-icons left ft-14 txt_main-dark-blue">clear</i>Cerrar</a>

                            : null
                    }


                </div>
            </div>

            <form hidden={pasos !== 1} className="row mt-1" onSubmit={(e) => {
                e.preventDefault();
                if (anioSelected !== '' && tipoSelected !== '') {
                    getDocumento();
                }
            }}>
                <div className="col s12 left-align">
                    <p className="ft-16">Selecciona el año y el formato de descarga.</p>
                </div>

                <div className="col s12 m6">
                    <p className="txtMainDB ft-14">Año de Consulta</p>
                    <select onChange={(e) => {
                        setAnioSelected(e.target.value);
                    }} className="browser-default">
                        <option value=''>Selecciona una opción</option>
                        <option value="2024">2024</option>
                    </select>

                </div>
                <div className="col s12"></div>

                <div className="col s12 m6">
                    <p className="txtMainDB ft-14">Formato de Descarga</p>
                    <select className="browser-default" onChange={(e) => {
                        setTipoSelected(e.target.value);
                    }}>
                        <option value=''>Selecciona una opción</option>
                        <option value="PDF">PDF</option>
                        <option value="XML">XML</option>
                    </select>
                </div>

                <div className="col s12 m8 left-align mt-1">
                    <button className="btn btnContinueF5"
                        disabled={anioSelected === '' || tipoSelected === ''}
                    >Descargar</button>
                </div>

            </form>

            {
                pasos === 2 && tipoSelected === 'PDF' ?
                    <div className="row mt-1" >
                        {
                            documento ?

                                <embed src={`data:application/pdf;base64,${documento}`} type="application/pdf" width="100%" height="600px" />

                                : null
                        }
                    </div>
                    : null

            }

        </div>
    )

}

export default ConstanciaFiscal;