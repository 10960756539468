import { createSlice } from '@reduxjs/toolkit'


export const appSlice = createSlice({
    name: 'appData',
    initialState: {
        user: false,
        token: false,
        idToken: false,
        legales: false,
        preguntas: false,
        coordenadas: false,
        ip: false,
        bitacora:[],
        productosInfo: [],
        educacionFinanciera: false,
        loading: false,
        finSesionModal: false,
        saldosFecha: false,
        saldosMovimientos: false,
        saldosPrestamos: false,
        saldosTrimestrales: false,
        tokenSesion: false,
        infoFondos: false,
        configPrestamo: false,
        configRetiro: false,
        muestraSaldo: false,
    },
    reducers: {
        loadUser: (state) => {
            document.body.classList.add('bodyUser')
            // let usr = localStorage.getItem('user');
            // let jwt = localStorage.getItem('token');
            // let idtoken = localStorage.getItem('idtoken');

            // if (usr) {
            //     let datos = JSON.parse(usr);
            //     console.log(datos);
            //     state.user = datos;
            //     state.token = JSON.parse(jwt);
            //     state.idToken = idtoken;
            //     document.body.classList.add('bodyUser')
            // } else {
            //     document.body.classList.add('bodyLogin')
            // }
        },
        setUser: (state, action) => {
            if (action.payload) {
                let usr = action.payload;
                localStorage.setItem('user', JSON.stringify(usr));
                state.user = action.payload
                document.body.classList.add('bodyUser')
            } else {
               

                localStorage.removeItem('user')
                state.user = false
                document.body.classList.remove('bodyUser')
                document.body.classList.add('bodyLogin')
                //Termina la sesión
                state.token = false;
                state.idToken= false;
                state.legales= false;
                state.bitacora=[];
                state.loading= false;
                state.finSesionModal= false;
                state.saldosFecha= false;
                state.saldosMovimientos= false;
                state.saldosPrestamos= false;
                state.saldosTrimestrales= false;
                state.tokenSesion= false;
                state.infoFondos= false;
                state.configPrestamo= false;
                state.configRetiro= false;
            }
        },
        setToken: (state, action) => {
       
            state.token = action.payload
            
        },
        setIdToken: (state, action) => {
         
            state.idToken = action.payload
           
        },
        setLegales: (state, action) => {
            state.legales = action.payload
        },
        setPreguntas: (state, action) => {
            state.preguntas = action.payload
        },
        setCoordenadas: (state, action) => {
            state.coordenadas = action.payload
        },
        setIp: (state, action) => {
            state.ip = action.payload
        },
        setBitacora: (state,action) =>{
            state.bitacora = action.payload
        },
        setProductosInfo: (state,action) =>{
            state.productosInfo = action.payload;
        },
        setEduFinanciera: (state,action) =>{
            state.educacionFinanciera = action.payload;
        },
        toogleLoad: (state, action)=>{
           
            state.loading = action.payload;
           
        },
        showModalFinSesion: (state, action)=>{
            state.finSesionModal = action.payload;
           
        },
        setSaldosFecha: (state, action) =>{
            state.saldosFecha = action.payload;
        }, 
        setSaldosMovimientos: (state, action)=>{
            state.saldosMovimientos = action.payload;
        },
        setSaldosPrestamos: (state,action) =>{
            state.saldosPrestamos = action.payload;
        },
        setSaldosTrimestrales: (state,action) =>{
            state.saldosTrimestrales = action.payload;
        },
        setTokenSesion: (state,action) =>{
        
            state.tokenSesion = action.payload;
            
        },
        setInfoFondos:(state, action) =>{
            state.infoFondos = action.payload;
        },
        setConfigPrestamo:(state, action) =>{
            state.configPrestamo = action.payload;
        },
        setConfigRetiro:(state, action) =>{
            state.configRetiro = action.payload;
        },
        setMuestraSaldoF: (state,action) =>{
            state.muestraSaldo = action.payload;
        }

    },
});

export const { setUser, 
                loadUser, 
                setToken, 
                setIdToken, 
                setTokenSesion,
                setLegales, 
                setPreguntas, 
                setCoordenadas,
                setIp,
                setBitacora,
                setProductosInfo,
                setEduFinanciera,
                toogleLoad,
                setSaldosFecha,
                setSaldosMovimientos,
                setSaldosPrestamos,
                setSaldosTrimestrales,
                setInfoFondos,
                setConfigPrestamo,
                setConfigRetiro,
                showModalFinSesion,
                setMuestraSaldoF
            } = appSlice.actions

export default appSlice.reducer

