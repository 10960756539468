import iconPrestamo from './prestamo.svg';
import iconSaldos from './saldoMovimiento.svg';
import iconPortafolio from './iconPortafolio.svg';
import elipseLleno from './elipseLleno.svg';
import elipseVacio from './elipseVacio.svg';
import solicitudAprobada  from './solicitudAprobada.svg';
import rebalanceoInversion from './iconoRebalanceo.svg';
import exitoRebalanceo  from './exitoRebalanceo.svg';
import noDisponible  from './nodisponible.svg';
import txtNoDisponible from './txtNoDisponible.svg';
import constanciaFiscal from './constanciaFiscal.svg';
import cancelar from './cancelar.svg';
import errorRebalanceo  from './errorRebalanceo.svg';
import txtErrorPorcentaje from './txtErrorPorcentaje.svg';
import txtErrorGRebalanceo from './txtErrorGRebalanceo.svg';
import checkOutline from './checkOutline.svg';
import sinInfo from './SinInfo.svg';


export const ICONS = {
    iconPrestamo,
    iconSaldos,
    iconPortafolio,
    elipseLleno,
    elipseVacio,
    solicitudAprobada,
    rebalanceoInversion,
    exitoRebalanceo,
    noDisponible,
    txtNoDisponible,
    constanciaFiscal,
    cancelar,
    errorRebalanceo,
    txtErrorPorcentaje,
    txtErrorGRebalanceo,
    checkOutline,
    sinInfo
}