import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router"
import { ICONS } from "../assets/icons";
import paso1Rebalanceo from '../assets/paso1Rebalanceo.svg';
import paso2Rebalanceo from '../assets/paso2Rebalanceo.svg';
import paso3Rebalanceo from '../assets/paso3Rebalanceo.svg';
import { useDispatch, useSelector } from "react-redux";
import {
    obtenerFechaActualString, encryptAES, nWithCommas, capitalizeFirstLetter,
    cambiaEfectivo, agregaAportacion, cambioEfectivo
} from '../utils';
import { useAnalytics } from "../AnalyticsContext";
import M from 'materialize-css';
import { BASE_URL } from "../properties";
import { toogleLoad, setTokenSesion, setProductosInfo, } from "../redux/slices/appslice";
import { getRebalanceoConfig } from "../firestore";
import { logEvent } from "firebase/analytics";
import { useLottie } from 'lottie-react';
import LoaderLottie from '../assets/loaderLottie.json';


function SolicitudRebalanceo() {

    const analytics = useAnalytics();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const appData = useSelector((state) => state.appData)

    const [paso, setPasos] = useState(1);
    const [saldosPorCuenta, setSaldosPorCuenta] = useState(false);
    const [dataSolicitud, setDataSolicitud] = useState({});
    const [valoresRebalanceo, setValoresRebalanceo] = useState({});
    const [rebalanceoValido, setRebalanceoValido] = useState(false);
    const [sumaPerRebalanceo, setSumaPerRebalanceo] = useState(0);
    const [cuentaSelected, setCuentaSelected] = useState("");
    const [configRebalanceo, setConfigRebalanceo] = useState(false);
    const [planes, setPlanes] = useState([]);
    const [cuentaToSend, setCuentaToSend] = useState(false);
    const [muestraError, setMuestraError] = useState('');
    const [valoresFinales, setValoresFinales] = useState(false);
    const [sumasTotales, setSumasTotales] = useState({ sumaSaldo: 0, sumaPorcentaje: 0 });
    const [procesaInfo, setProcesaInfo] = useState(false);
    const [tempValue, setTempValue] = useState({});
    const [errorGenerico, setErrorGenerico] = useState(false);

    const options = {
        animationData: LoaderLottie,
        loop: true
    };

    const { View } = useLottie(options);

    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: '',
        producto: ''
    });

    useEffect(() => {
        if (paso === 3) {
            setValoresFinales(agregarNuevoPorcentaje(planes, valoresRebalanceo));
        }
    }, [paso]);

    useEffect(() => {
        if (planes.length) {
            M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
        }
    }, [planes])


    function agregarNuevoPorcentaje(arreglo, objeto) {
        return arreglo.map(item => ({
            ...item,
            nuevoPorcentaje: objeto[item.idPlan] ? parseInt(objeto[item.idPlan], 10) : 0,
        }));
    }

    useEffect(() => {
        if (dataPage.producto !== '' && appData.saldosFecha) {

            for (let saldo of appData.saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === dataPage.producto) {
                        getConfigRebalanceo(saldo.Producto[0].NombreCliente);
                    }
                }
            }
        }
    }, [dataPage])




    useEffect(() => {
        if (params.tipo && params.tipo === 'plandepensiones') {

            setDataPage({
                titulo: 'Fondo de Pensiones',
                to_return: '/plandepensiones',
                producto: 'FONDO DE PENSIONES'
            });

        } else if (params.tipo && params.tipo === 'cajadeahorro') {

            setDataPage({
                titulo: 'Caja de Ahorro',
                to_return: '/cajadeahorro',
                producto: 'CAJA DE AHORRO'
            });

        } else {
            navigate('/')
        }

    }, [params])


    useEffect(() => {
        rebalanceoValidoF();
    }, [valoresRebalanceo]);

    useEffect(() => {

        if (dataPage.titulo !== "") {
            getSaldosPorCuenta();
        }

    }, [dataPage]);

    useEffect(() => {
        M.Modal.init(document.querySelectorAll('.modal'), { dismissible: false });
        M.FormSelect.init(document.querySelectorAll('select'), {});
    }, [])

    const getConfigRebalanceo = async (empresaCliente) => {

        const configRebalanceo = await getRebalanceoConfig();

        if (configRebalanceo[empresaCliente]) {
            setConfigRebalanceo(configRebalanceo[empresaCliente]);
        }
    }


    const buscarProducto = (producto, productos) => {

        const existe = productos.filter(p => p.TipoCliente === producto);

        return existe;
    }



    const getSaldosPorCuenta = async () => {
        dispatch(toogleLoad(true));

        const dataUserProducto = buscarProducto(dataPage.titulo, appData.user.Productos)

        if (dataUserProducto.length && appData.token && appData.tokenSesion) {

            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "IdCliente": dataUserProducto[0].IdCliente,
                "IdEmpleado": dataUserProducto[0].IdEmpleado,
                "FechaIni": "20240801",
                "FechaFin": obtenerFechaActualString(),
                "TodaLaHistoria": "True",
                "SistemaOperativo": "web",
                "AppID": "SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token,
                "DeviceToken": "dv1"
            }

       

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/rebalanceos/saldosPorCuenta`, requestOptions)
                .then(response => response.json())
                .then((data) => {

                    if (data.Datos && data.JWT && data.Datos.length) {

                        dispatch(setTokenSesion(data.JWT.current_token))

                        dispatch(toogleLoad(false));

                        setSaldosPorCuenta(data.Datos);

                    } else {
                        dispatch(toogleLoad(false));
                    }
                }).catch((e) => {

                    dispatch(toogleLoad(false));

                });
        }


    }


    const rebalanceoValidoF = async () => {
        //obtiene el tamaño de sados por cuenta.
        setMuestraError(false);

        let sumaValores = 0;
        //obtiene la suma total de los valores para rebalanceo
        for (let key of Object.keys(valoresRebalanceo)) {
            sumaValores += parseInt(valoresRebalanceo[key]);
        }

        if (sumaValores > 100) {
            setMuestraError('Verifica que los porcentajes asigandos a tus recursos sumen 100%.');
        }

        setSumaPerRebalanceo(sumaValores);

        if (sumaValores === 100) {
            setRebalanceoValido(true);
        } else {
            setRebalanceoValido(false);
        }
    }

    function mayusFL(text) {
        if (!text) return ''; // Manejar caso de texto vacío o undefined
        return text.charAt(0).toUpperCase() + text.slice(1);
    }


    const generaSolicitud = async () => {
        //dispatch(toogleLoad(true));
        setProcesaInfo(true);
        setErrorGenerico(false);

        const dataUserProducto = buscarProducto(dataPage.titulo, appData.user.Productos)
        if (Object.keys(valoresRebalanceo).length && rebalanceoValido && dataUserProducto.length) {

            let listaDistribucion = [];

            for (let vf of valoresFinales) {

                listaDistribucion.push({
                    IdCuenta: parseInt(cuentaToSend.idCuenta),
                    IdPlan: parseInt(vf.idPlan),
                    Porcentaje: parseInt(vf.nuevoPorcentaje)
                })
            }

            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "IdCliente": dataUserProducto[0].IdCliente,
                "IdEmpleado": dataUserProducto[0].IdEmpleado,
                "FechaIni":  obtenerFechaActualString(),
                "FechaFin": obtenerFechaActualString(),
                "ListaDistribucion": listaDistribucion,
                "DeviceToken": "dv1",
                "SistemaOperativo": "web",
                "AppID": "SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token
            }

       
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/rebalanceos/solicitudRebalanceo`, requestOptions)
                .then(response => response.json())
                .then((data) => {
                 
                    if (data.BanderaExito) {

                        dispatch(setTokenSesion(data.JWT.current_token))

                        //dispatch(toogleLoad(false));
                        setProcesaInfo(false);

                        logEvent(analytics, "rebalanceo_enviado", {
                            screen_name: "rebalanceo",
                            plataforma: "web"
                        });


                        setPasos(4);

                    } else if (data.BanderaExito === 0 && data.IdError === 33) {

                        if (data.Mensaje.trim() === 'La suma de los porcentajes debe ser 100%') {

                            const instanceModal = M.Modal.getInstance(document.getElementById('modalError'));

                            if (instanceModal) {
                                //dispatch(toogleLoad(false));
                                setProcesaInfo(false);
                                instanceModal.open();
                            }

                        } else {
                            const instanceModal = M.Modal.getInstance(document.getElementById('modalSolicitudPrevia'));

                            if (instanceModal) {
                                //dispatch(toogleLoad(false));
                                setProcesaInfo(false);
                                instanceModal.open();
                            }
                        }



                    } else if (data.BanderaExito === 0 && data.Mensaje.trim() !== "") {
                        const instanceModal = M.Modal.getInstance(document.getElementById('modalError'));

                        if (instanceModal) {
                            //dispatch(toogleLoad(false));
                            setErrorGenerico(data.Mensaje.trim());
                            setProcesaInfo(false);
                            instanceModal.open();
                        }
                    }
                }).catch((e) => {
                    setMuestraError("Ocurrio un error inesperado intente nuevamente.");

                });
        }
    }


    function getTextPaso() {

        if (paso === 1) {
            return "1. Selecciona una cuenta "
        }


        if (paso === 2) {
            return "2. Configura el porcentaje de inversión "
        }

        if (paso === 3) {
            return "3. Confirmación de solicitud"
        }

        return "";
    }

    function searchPlanes(cuentaS) {
        if (configRebalanceo) {
            //Busca que la cuenta exista
            const cuentas = configRebalanceo.cuentas;

            for (let cuenta of cuentas) {
                if (cuenta.descripcion === cuentaS.trim() && location.state) {

                    setCuentaToSend(cuenta);

                    const planesT = agregarSaldo(configRebalanceo.planes, agregarPorcentaje(location.state.aportacionesSeparadas[cuentaS]));

                    calcularSuma(planesT);

                    setPlanes(planesT);

                }


            }
        }
    }

    function agregarPorcentaje(arreglo) {
        // Calcular la suma total de los saldos
        const totalSaldo = arreglo.reduce((sum, item) => sum + item.saldo, 0);

        // Calcular porcentajes iniciales y redondear a enteros
        let porcentajes = arreglo.map(item => Math.floor((item.saldo / totalSaldo) * 100));

        // Ajustar los porcentajes para que sumen exactamente 100
        let sumaPorcentajes = porcentajes.reduce((sum, p) => sum + p, 0);
        let diferencia = 100 - sumaPorcentajes;

        // Aplicar la diferencia distribuyéndola proporcionalmente
        while (diferencia !== 0) {
            for (let i = 0; i < porcentajes.length && diferencia !== 0; i++) {
                porcentajes[i] += diferencia > 0 ? 1 : -1;
                diferencia += diferencia > 0 ? -1 : 1;
            }
        }

        // Agregar el porcentaje ajustado a cada elemento del arreglo
        return arreglo.map((item, index) => ({
            ...item,
            porcentaje: porcentajes[index],
        }));
    }

    function agregarSaldo(arreglo1, arreglo2) {
        return arreglo1.map(item1 => {
            // Buscar en el arreglo2 el objeto cuyo campo fondo coincida con descripcion
            const matchingItem = arreglo2.find(item2 => item2.fondo === item1.descripcion);
            // Si se encuentra coincidencia, agregar el campo saldo; si no, dejar saldo en null
            return { ...item1, saldo: matchingItem ? matchingItem.saldo : null, porcentaje: matchingItem ? matchingItem.porcentaje : null };
        });
    }

    function calcularSuma(arr) {
        let sumaSaldo = 0;
        let sumaPorcentaje = 0;

        arr.forEach(item => {
            if (item.saldo !== null && item.saldo !== undefined) {
                sumaSaldo += item.saldo;
            }
            if (item.porcentaje !== null && item.porcentaje !== undefined) {
                sumaPorcentaje += item.porcentaje;
            }
        });

        setSumasTotales({ sumaSaldo, sumaPorcentaje });
    }

    return (
        <div className="container mt-2" style={{ minHeight: '90vh' }}>
            <div className="row">

                <div className="col s12 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> <span className="ft-16 txtBold">{dataPage.titulo}</span></p>
                </div>
                <div className="col s4 left-align">
                    <span className="ft-24 boldSans">Rebalancear Inversión</span>
                </div>
                <div className="col s4 center-align mt-">
                    <span className="ft-14 ">{getTextPaso()}</span>
                </div>
                <div className="col s4 right-align" style={{ paddingTop: '10px' }}>
                    {
                        paso !== 4 ?
                            <a className="waves-effect waves-light btn ft-14 txt_main-dark-blue white br-12 btnCancel txtBold"
                                onClick={() => {
                                    logEvent(analytics, "rebalanceo_cancelacion", {
                                        screen_name: "rebalanceo",
                                        plataforma: "web"
                                    });
                                    navigate(dataPage.to_return)
                                }}
                            ><i className="material-icons left ft-14 txt_main-dark-blue">clear</i>Cancelar</a>
                            : null

                    }

                </div>
                <div className="col s12">
                    <div className="row">

                        <div className="col s4"></div>
                        <div className="col s4 center-align">

                            {
                                paso === 1 ?
                                    <img src={paso1Rebalanceo} alt="paso" />
                                    : null
                            }
                            {
                                paso === 2 ?
                                    <img src={paso2Rebalanceo} alt="paso" />
                                    : null
                            }
                            {
                                paso === 3 ?
                                    <img src={paso3Rebalanceo} alt="paso" />
                                    : null
                            }

                        </div>

                        <div className="col s4"></div>
                    </div>
                </div>

                {
                    muestraError ?

                        <div className="col s12">
                            <div style={{
                                background: '#F27468',
                                color: 'white',
                                borderRadius: '8px',
                                padding: '8px 16px 8px 16px',
                                fontWeight: 'bold'

                            }}>
                                <img src={ICONS.cancelar} alt="error" className="left iconCancelar"></img> {muestraError}
                            </div>
                        </div>


                        : null
                }
            </div>

            <div className="row" hidden={paso !== 1}>
                <div className="col s12 m3"></div>
                <div className="col s12 m6">

                    <select className="browser-default"
                        onChange={(e) => {
                            setCuentaSelected(e.target.value);
                            searchPlanes(e.target.value);
                        }}
                    >
                        <option value="">Selecciona una opción</option>
                        {
                            location.state && Object.keys(location.state.aportacionesSeparadas).map((k, i) => {
                                return (
                                    <option value={k}>
                                        {mayusFL(capitalizeFirstLetter(cambioEfectivo(cambiaEfectivo(agregaAportacion(k)))))}
                                    </option>
                                )
                            })
                        }
                    </select>

                </div>
                <div className="col s12 m3"></div>

                <div className="col s12 center-align mt-1">
                    <button className="btn btnContinueF5"
                        disabled={cuentaSelected.trim() === ''}
                        onClick={() => {
                            setPasos(2);
                        }}
                    >Continuar</button>
                </div>
            </div>

            <div className="row" hidden={paso !== 2}>
                <div className="col s12">
                    <p className="mb-0">Selecciona un fondo y ajusta el porcentaje de Inversión, <span className="colorDarkBlue boldSans">tomando en cuenta que la suma sea el 100% de tus recursos.</span> Los cambios se reflejarán una vez sean aprobados por tu empresa.</p>
                </div>

                <div className="col s12 mt-2">
                    <table>
                        <thead style={{ background: '#F6F8FA' }}>
                            <tr className="borderCyan" style={{ color: '#797F97' }}>
                                <th>Fondo</th>
                                <th className="right-align">Saldo Actual</th>
                                <th className="right-align ">Porcentaje Actual</th>
                                <th className="right-align">Porcentaje Deseado</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                planes.length &&
                                planes.map((f) => {

                                    return (
                                        <tr className="borderCyan" key={f.idPlan}>
                                            <td > <span style={{ fontWeight: '600' }}>{cambioEfectivo(f.descripcion)}</span>

                                                {cambioEfectivo(f.descripcion).toUpperCase() === 'EFECTIVO' ?

                                                    <span><i className="material-icons tooltipped cursorPointer"
                                                        data-position="right"
                                                        data-tooltip="Es dinero de tus aportaciones que no se ha ingresado a los fondos de inversión. Genera rendimientos diarios a la tasa de fondeo."
                                                        style={{
                                                            color: '#969191', position: 'absolute',
                                                            marginTop: '-1px',
                                                            marginLeft: '4px'
                                                        }}>info</i></span>
                                                    : null}

                                            </td>
                                            <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" value={`$${nWithCommas(parseFloat(f.saldo).toFixed(2))}`} disabled /> </td>
                                            <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo_pa" value={`${!f.porcentaje ? '0%' : `${f.porcentaje}%`}`} disabled /></td>
                                            <td className="container_inputRebalanceo">
                                                <input
                                                    type="text"
                                                    className={

                                                        cambioEfectivo(f.descripcion).toUpperCase() === 'EFECTIVO'
                                                            ? 'txt_rebalanceo_pa'
                                                            : `txt_rebalanceo ${tempValue[f.idPlan] !== undefined && tempValue[f.idPlan] !== '' ? 'active' : ''} `
                                                    }
                                                    id={f.idPlan}
                                                    disabled={cambioEfectivo(f.descripcion).toUpperCase() === 'EFECTIVO'}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, ''); // Solo números
                                                    }}
                                                    onChange={(e) => {
                                                        let rawValue = e.target.value.replace(/\D/g, ''); // Solo números

                                                        let copyValores = { ...valoresRebalanceo };
                                                        let copyTemp = { ...tempValue };

                                                        if (rawValue === '') {
                                                            delete copyValores[f.idPlan];
                                                            delete copyTemp[f.idPlan];
                                                        } else {
                                                            copyValores[f.idPlan] = rawValue; // Guardamos solo el número
                                                            copyTemp[f.idPlan] = rawValue; // También actualizamos la vista temporal
                                                        }

                                                        setValoresRebalanceo(copyValores);
                                                        setTempValue(copyTemp);
                                                    }}
                                                    onFocus={(e) => {
                                                        let rawValue = valoresRebalanceo[f.idPlan] || '';
                                                        setTempValue((prev) => ({ ...prev, [f.idPlan]: rawValue })); // Mostrar sin %
                                                    }}
                                                    onBlur={(e) => {
                                                        let rawValue = valoresRebalanceo[f.idPlan];
                                                        setTempValue((prev) => ({ ...prev, [f.idPlan]: rawValue !== undefined ? `${rawValue}%` : '' })); // Agregar % solo cuando pierde el foco
                                                    }}
                                                    value={
                                                        cambioEfectivo(f.descripcion).toUpperCase() === 'EFECTIVO'
                                                            ? '0%'
                                                            : tempValue[f.idPlan] !== undefined
                                                                ? tempValue[f.idPlan]
                                                                : ''
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            <tr className="borderCyan" >
                                <td style={{ color: '#202A52' }} className="boldSans">Total</td>
                                <td className="container_inputRebalanceo">
                                    <input type="text" className="txt_rebalanceo"
                                        value={`$${nWithCommas(parseFloat(sumasTotales.sumaSaldo).toFixed(2))}`} disabled />
                                </td>
                                <td className="container_inputRebalanceo">
                                    <input type="text" className="txt_rebalanceo_pa"
                                        value={`${sumasTotales.sumaPorcentaje}%`} disabled />
                                </td>
                                <td className="container_inputRebalanceo">
                                    <input
                                        type="text"
                                        className={`txt_rebalanceo ${sumaPerRebalanceo < 100 || sumaPerRebalanceo > 100 ? "errorVal" : ""}`}
                                        id="totalRebalanceo"
                                        value={`${isNaN(sumaPerRebalanceo) ? 0 : sumaPerRebalanceo}%`}
                                        disabled
                                    />
                                </td>

                            </tr>




                            <tr>
                                <td colSpan={3}></td>
                                <td className="right-align"><button className="btn btnContinueF5" disabled={!rebalanceoValido}
                                    onClick={() => {

                                        if (rebalanceoValido) {

                                            logEvent(analytics, "rebalanceo_configuracion", {
                                                screen_name: "rebalanceo",
                                                plataforma: "web"
                                            });


                                            setPasos(3);
                                        }

                                    }}
                                >Continuar</button></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row" hidden={paso !== 3 || procesaInfo} >
                <div className="col s12">
                    <div className="row left-align">
                        <div className="col s12 m2"></div>
                        <div className="col s12 m8 mt-4 center-align">
                            <span style={{ fontWeight: '600', color: '#4D5575' }} className="boldSans">Verifica los porcentajes.</span> Asegúrate de que los porcentajes asignados a tus recursos sean correctos.
                        </div>
                        <div className="col s12 m2"></div>

                        <div className="col s12 m3"></div>
                        <div className="col s12 m7 mt-2">
                            <div className="row" >
                                {
                                    valoresFinales &&
                                    valoresFinales.filter((v) => cambioEfectivo(v.descripcion).toUpperCase() === 'EFECTIVO' || v.nuevoPorcentaje && v.nuevoPorcentaje > 0  ).map((vf) => {
                                        return (
                                            <div className="col s12">

                                                <div className={`row cardVF ${cambioEfectivo(vf.descripcion).toUpperCase() === 'EFECTIVO' ? 'efectivo' : ''}`}>

                                                    <div className="col s12 left-align boldSans"
                                                        style={{
                                                            fontSize: '20px',
                                                            color: '#4D5575',
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {cambioEfectivo(vf.descripcion)}
                                                    </div>
                                                    <div className="col s12 mt-1">
                                                        <div className="divider" />
                                                    </div>

                                                    <div className="col s6 left-align mt-1">
                                                        Saldo Actual
                                                    </div>
                                                    <div className="col s6 right-align mt-1">
                                                        ${nWithCommas(`${parseFloat(vf.saldo).toFixed(2) || 0.00}`)}
                                                    </div>

                                                    {
                                                        cambioEfectivo(vf.descripcion).toUpperCase() === 'EFECTIVO'

                                                            ?
                                                            <>
                                                                <div className="col s6 left-align cardVFResaltar mt-1 boldSans">
                                                                    Porcentaje Actual
                                                                </div>
                                                                <div className="col s6 right-align cardVFResaltar mt-1 boldSans">
                                                                    {vf.porcentaje || 0}%
                                                                </div>
                                                            </>

                                                            : null
                                                    }

                                                    {
                                                        cambioEfectivo(vf.descripcion).toUpperCase() !== 'EFECTIVO'

                                                            ?
                                                            <>
                                                                <div className="col s6 left-align cardVFResaltar mt-1 boldSans">
                                                                    Porcentaje Deseado
                                                                </div>
                                                                <div className="col s6 right-align cardVFResaltar mt-1 boldSans">
                                                                    {vf.nuevoPorcentaje || 0}%
                                                                </div>
                                                            </>

                                                            : null
                                                    }




                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="col s12 " style={{ marginLeft: '15%' }}>
                                    <button className="btn btnContinueF5" disabled={!rebalanceoValido}
                                        onClick={() => {

                                            logEvent(analytics, "rebalanceo_solicitud", {
                                                screen_name: "rebalanceo",
                                                plataforma: "web"
                                            });

                                            generaSolicitud();
                                        }}
                                    >Guardar Cambios</button>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 m2"></div>
                    </div>
                </div>
            </div>

            <div className="row containerSuccess" hidden={!procesaInfo}>
                <div className="col s12 center-align">
                    <div className="row">
                        <div className="col s12">
                            {View}
                        </div>
                        <div className="col s12 center-align ft-16">
                            Estamos procesando tu solicitud.
                        </div>
                    </div>
                </div>
            </div>

            <div className="row containerSuccess" hidden={paso !== 4}>
                <div className="col s12 center-align">
                    <div className="row">

                        <div className="col s12" style={{
                            fontSize: '24px',
                            fontWeight: '800',
                            color: '#1F3158'
                        }}>
                            ¡Tu solicitud se ha enviado correctamente!
                        </div>
                        <div className="col s12 mt-2">
                            <img src={ICONS.exitoRebalanceo} alt="solicitud aprobada" />
                        </div>

                        <div className="col s12 mt-2">
                            Los cambios se reflejarán una vez que sean aprobados
                        </div>

                        <div className="col s12 mt-2">
                            <button className="btn btnContinueF5"
                                onClick={() => {
                                    navigate(dataPage.to_return)
                                }}
                            >Entendido</button>
                        </div>

                    </div>
                </div>

            </div>


            <div id="modalSolicitudPrevia" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12 center-align mt-1">
                            <img src={ICONS.txtNoDisponible} alt="no disponible"></img>
                        </div>
                        <div className="col s12 center-align mt-2">
                            <img src={ICONS.noDisponible} alt="no disponible" ></img>
                        </div>
                        <div className="col s12 center-align mt-2" >
                            <span
                                style={{
                                    fontSize: '16px',
                                    color: '#81879E'
                                }}
                            >Debido a que actualmente, ya cuentas con una solicitud pendiente.</span>
                        </div>
                        <div className="col s12 center-align mt-2">
                            <button className="btn btnContinueF5 modal-close"
                                onClick={() => {
                                    logEvent(analytics, "rebalanceo_solPendiente", {
                                        screen_name: "rebalanceo",
                                        plataforma: "web"
                                    });
                                }}
                            >Entendido</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalError" className="modal modalRebalanceo">
                {
                    errorGenerico ?

                        <div className="modal-content">
                            <div className="row">
                                <div className="col s12 center-align mt-1">
                                    <img src={ICONS.txtErrorGRebalanceo} alt="no disponible"></img>
                                </div>
                                <div className="col s12 center-align mt-2">
                                    <img src={ICONS.errorRebalanceo} alt="no disponible" ></img>
                                </div>
                                <div className="col s12 center-align mt-2" >
                                    <span
                                        style={{
                                            fontSize: '16px',
                                            color: '#81879E'
                                        }}
                                    >{errorGenerico}</span>
                                </div>
                                <div className="col s12 center-align mt-2">
                                    <button className="btn btnContinueF5 modal-close"
                                        onClick={() => {
                                            logEvent(analytics, "rebalanceo_errorPorcentaje", {
                                                screen_name: "rebalanceo",
                                                plataforma: "web"
                                            });
                                        }}
                                    >Entendido</button>
                                </div>
                            </div>
                        </div>

                        :

                        <div className="modal-content">
                            <div className="row">
                                <div className="col s12 center-align mt-1">
                                    <img src={ICONS.txtErrorPorcentaje} alt="no disponible"></img>
                                </div>
                                <div className="col s12 center-align mt-2">
                                    <img src={ICONS.errorRebalanceo} alt="no disponible" ></img>
                                </div>
                                <div className="col s12 center-align mt-2" >
                                    <span
                                        style={{
                                            fontSize: '16px',
                                            color: '#81879E'
                                        }}
                                    >Asegúrate de que la suma de los porcentajes asignados a tus <span className="boldSans">recursos sea del 100%.</span></span>
                                </div>
                                <div className="col s12 center-align mt-2">
                                    <button className="btn btnContinueF5 modal-close"
                                        onClick={() => {
                                            logEvent(analytics, "rebalanceo_errorPorcentaje", {
                                                screen_name: "rebalanceo",
                                                plataforma: "web"
                                            });
                                        }}
                                    >Entendido</button>
                                </div>
                            </div>
                        </div>
                }

            </div>
        </div>
    )
}


export default SolicitudRebalanceo;